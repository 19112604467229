import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import SigninView from '@/views/SigninView.vue'
import DashboardView from '@/views/DashboardView.vue'
import AssetView from '@/views/AssetView.vue'
import OrderVIew from '@/views/OrderVIew.vue'
import ChatView from '@/views/ChatView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/signin',
    name: 'signin',
    component: SigninView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    children: [
      {
        path: 'asset',
        name: 'AssetView',
        component: AssetView
      },
      {
        path: 'order',
        name: 'OrderVIew',
        component: OrderVIew
      },
      {
        path: 'learn',
        name: 'ChatView',
        component: ChatView
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
