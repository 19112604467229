<template>
    <div>
        <!-- 訂單標題 -->
        <div
            class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">訂單</h1>
        </div>

        <!-- 訂單列表表格 -->
        <div class="table-responsive">
            <table class="table table-striped table-sm">
                <thead>
                    <tr>
                        <th scope="col">策略名稱</th>
                        <th scope="col">交易對</th>
                        <th scope="col">持倉數量</th>
                        <th scope="col">合約價值</th>
                        <th scope="col">未平盈虧</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- 如果 orderList 沒有資料，顯示無資料提示 -->
                    <tr v-if="orderList.length === 0">
                        <td colspan="7" class="text-center">無資料</td>
                    </tr>
                    <tr v-else v-for="(item, index) in orderList" :key="index">
                        <td>{{ item.StrategyId }}</td>
                        <td>{{ item.Symbol }}</td>
                        <td>{{ item.PositionAmt }}</td>
                        <td>{{ item.Notional }}</td>
                        <td>{{ item.UnrealizedProfit }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import { GetPositionList } from '@/apiService'

export default {
  name: 'OrderView',
  data () {
    return {
      // 訂單列表數據
      orderList: []
    }
  },
  methods: {
    getPositionList () {
      GetPositionList((error, data) => {
        if (!error && data) {
          this.orderList = data
        }
      })
    }
  },
  mounted () {
    this.getPositionList()
    // 初始化圖表代碼可以在這裡新增
    // 例如：this.initMyChart() 和 this.initWalletDiffChart()
  }
}
</script>

<style scoped>
/* 在此處添加您的樣式 */
</style>
