<template>
  <div class="chat-container">
    <!-- System Message Input -->
    <div class="system-message-container">
      <input
        type="text"
        id="systemMessage"
        v-model="systemMessage"
        placeholder="輸入系統訊息"
      />
    </div>
    <div class="chat-box" ref="chatBox">
      <div v-for="(message, index) in messages" :key="index" class="message">
        <div :class="message.role === 'user' ? 'user-msg' : 'ai-msg'">
          <span class="sender"></span>
          <div class="content" v-html="renderMarkdown(message.content)"></div>
        </div>
      </div>
      <div v-if="loading" class="loading-indicator">正在等待回覆...</div>
    </div>
    <div class="input-box">
      <textarea v-model="input" placeholder="輸入訊息..." @keydown.enter.exact.prevent="sendMessage"></textarea>
      <button @click="sendMessage">傳送</button>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick } from 'vue'
import { marked } from 'marked'
import hljs from 'highlight.js'
import 'highlight.js/styles/github.css' // 使用 GitHub 風格語法高亮
import { GetAIMessage } from '@/apiService'

// 資料與方法
const input = ref('')
const loading = ref(false) // 新增 loading 狀態
const chatBox = ref(null)
const systemMessage = ref('you are a helpful assiatant.') // 預設的系統訊息
const messages = ref([])
const allMessages = ref([{ role: 'system', content: systemMessage.value + ' Always respond in Traditional Chinese.' }])

// Markdown 解析
const renderMarkdown = (content) => {
  marked.setOptions({
    highlight: function (code, lang) {
      return hljs.highlightAuto(code).value
    }
  })
  return marked(content)
}

// 發送訊息
const sendMessage = async () => {
  if (!input.value.trim()) return

  const systemMsg = allMessages.value.find(item => item.role === 'system')
  systemMsg.content = systemMessage.value + ' Always respond in Traditional Chinese.'

  // 使用者訊息
  messages.value.push({ role: 'user', content: input.value })
  allMessages.value.push({ role: 'user', content: input.value })

  // const userInput = input.value
  input.value = '' // 清空輸入框

  await nextTick()
  scrollToBottom()

  // 顯示 loading 提示
  loading.value = true

  try {
    // 呼叫後端 API
    await GetAIMessage(allMessages.value, (error, data) => {
      messages.value.push({ role: 'assistant', content: '' + data || '無回覆內容' })
      if (!error && data) {
        allMessages.value.push({ role: 'assistant', content: '' + data })
      }
    }) // axios.post('https://localhost:7192/api/Learn/GetMessage', allMessages.value)
    // AI 回覆
    // await nextTick()
    // scrollToBottom()
  } catch (error) {
    console.error('Error:', error)
    messages.value.push({ role: 'assistant', content: '抱歉，出現錯誤，請稍後再試。' })
  } finally {
    // 隱藏 loading 提示
    loading.value = false
    await nextTick()
    scrollToBottom()
  }
}

// 自動滾到底部
const scrollToBottom = () => {
  if (chatBox.value) {
    chatBox.value.scrollTop = chatBox.value.scrollHeight
  }
}
</script>

<style scoped>
.chat-container {
  /* max-width: 800px; */
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  height: 85vh;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  margin: 10px 0;
}

.system-message-container {
  margin: 10px;
  padding: 10px;
  border-radius: 4px;
}

.system-message-container input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.chat-box {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background: #f9f9f9;
}

.message {
  margin: 10px 0;
}

.user-msg {
  text-align: right;
}

.ai-msg {
  text-align: left;
}

.sender {
  font-weight: bold;
}

.content {
  display: inline-block;
  padding: 8px 12px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: #e0e0e0;
}

.user-msg .content {
  background-color: #d1e7dd;
  color: #0f5132;
}

.input-box {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background: #fff;
}

textarea {
  flex: 1;
  resize: none;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
