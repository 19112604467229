<template>
    <div class="container mt-5">
      <div class="row mb-3">
        <div class="col-md-4">
          <button @click="generateRandomKey" class="btn btn-primary w-100">產生Key+Copy</button>
        </div>
        <div class="col-md-8">
          <div class="border p-2">{{ random16word }}</div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-4">
          <button @click="setKey" class="btn btn-secondary w-100">設定Key</button>
        </div>
        <div class="col-md-8">
          <input v-model="encryptionKey" type="text" class="form-control" />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-8 offset-md-4">
          <div class="text-danger">{{ alertMsg }}</div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-8">
          <input ref="enFileInput" type="file" class="form-control" multiple />
        </div>
        <div class="col-md-4">
          <button @click="encryptFile" class="btn btn-success w-100">加密</button>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-8">
          <input ref="deFileInput" type="file" class="form-control" multiple />
        </div>
        <div class="col-md-4">
          <button @click="decryptFile" class="btn btn-warning w-100">解密</button>
        </div>
      </div>
    </div>
  </template>

<script setup>
import CryptoJS from 'crypto-js'
import { ref } from 'vue'

const encryptionKey = ref('')
const alertMsg = ref('')
const random16word = ref('')
const enFileInput = ref(null)
const deFileInput = ref(null)

function generateRandomKey () {
  random16word.value = CryptoJS.SHA256('' + Date.now())
    .toString(CryptoJS.enc.Hex)
    .substring(0, 16)
  alertMsg.value = '複製成功'
  navigator.clipboard.writeText(random16word.value)
}

function setKey () {
  if (encryptionKey.value.length !== 16) {
    alertMsg.value = '需16碼'
    return
  }
  alertMsg.value = '設定完成'
}

function encryptFile () {
  const file = enFileInput.value.files[0]
  if (!file) {
    alertMsg.value = '請選擇一個檔案！'
    return
  }

  const reader = new FileReader()
  reader.onload = (event) => {
    const base64String = event.target.result
    const encryptedString = encrypt(file.name + '|' + base64String, encryptionKey.value)
    downloadEncryptedFile(encryptedString)
  }
  reader.readAsDataURL(file)
}

function decryptFile () {
  const file = deFileInput.value.files[0]
  if (!file) {
    alertMsg.value = '請選擇一個檔案！'
    return
  }

  const reader = new FileReader()
  reader.onload = (event) => {
    const decryptedString = decrypt(event.target.result, encryptionKey.value)
    downloadDecryptedFile(decryptedString)
  }
  reader.readAsText(file)
}

function encrypt (text, key) {
  const keyBytes = CryptoJS.enc.Utf8.parse(key)
  const iv = CryptoJS.lib.WordArray.random(16)
  const encrypted = CryptoJS.AES.encrypt(text, keyBytes, { iv: iv })
  return iv.toString() + encrypted.toString()
}

function decrypt (encryptedText, key) {
  const keyBytes = CryptoJS.enc.Utf8.parse(key)
  const iv = CryptoJS.enc.Hex.parse(encryptedText.substr(0, 32))
  const encrypted = encryptedText.substr(32)
  const decrypted = CryptoJS.AES.decrypt(encrypted, keyBytes, { iv: iv })
  return CryptoJS.enc.Utf8.stringify(decrypted)
}

function downloadEncryptedFile (encryptedString) {
  const blob = new Blob([encryptedString], { type: 'text/plain' })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = formatDate(new Date()) + '_encrypted.txt'
  link.click()
}

function downloadDecryptedFile (decryptedString) {
  const fileName = decryptedString.split('|')[0]
  const fileData = decryptedString.split('|')[1]
  const link = document.createElement('a')
  link.href = fileData
  link.download = formatDate(new Date()) + '_' + fileName
  link.click()
}

function formatDate (date) {
  const pad = (num) => num.toString().padStart(2, '0')
  return `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}_${pad(date.getHours())}${pad(date.getMinutes())}${pad(date.getSeconds())}`
}
</script>

  <style scoped>
  .container {
    max-width: 600px;
  }
  </style>
