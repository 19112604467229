<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">CoinGo</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link to="/" class="nav-link">首頁</router-link>
                        <!-- <a class="nav-link active" aria-current="page" href="#">首頁</a> -->
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" href="#">匯率轉換</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">投資計算器</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">交易記錄</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">關於我們</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">聯繫我們</a>
                    </li> -->
                    <li class="nav-item">
                      <router-link v-if="isLoggedIn" to="/signin" class="nav-link" @click="logout">登出</router-link>
                      <router-link v-else to="/signin" class="nav-link">登入</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
  <router-view/>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'App',
  setup () {
    const store = useStore()

    // 取得 isLoggedIn 狀態
    const isLoggedIn = computed(() => store.state.isLoggedIn)

    return {
      isLoggedIn,
      store
    }
  },
  mounted () {
    this.isLoggedIn = !!sessionStorage.getItem('token')
  },
  methods: {
    // 模擬登入函式
    login () {
      this.isLoggedIn = true // 登入時設為 true
    },
    // 模擬登出函式
    logout () {
      sessionStorage.removeItem('token')
      this.isLoggedIn = false // 登出時設為 false
      this.$store.dispatch('logout') // 更新 Vuex 狀態
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
