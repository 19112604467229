// src/apiService.js
import axios from 'axios'
import router from './router'

const apiDomain = process.env.VUE_APP_COINGO_API_DOMAIN
// const apiDomain = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' ? 'https://localhost:7192' : 'https://coingo.com.tw'
// console.log('API Domain:', process.env.VUE_APP_COINGO_API_DOMAIN)
const apiClient = axios.create({
  baseURL: apiDomain, // 'https://your-api-domain.com/api', // 替換為你的 .NET Core API 路徑
  headers: {
    'Content-Type': 'application/json'
  }
})

// 添加請求攔截器（可選）
apiClient.interceptors.request.use(config => {
  // 如果需要，可以在這裡添加身份驗證 Token
  const token = sessionStorage.getItem('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, error => {
  return Promise.reject(error)
})

// 添加響應攔截器（可選）
apiClient.interceptors.response.use(
  response => {
    console.log('Response:', response)
    if (response.status === 401) {
      // 跳轉到登入頁面
      router.push('/signin') // 替換為 Vue Router 中設定的登入路徑
    }
    // 如果請求成功，返回響應
    return response
  },
  error => {
    // 統一處理錯誤
    console.error('API Error:', error)
    // 當發生錯誤時檢查錯誤狀態碼
    if (error.response && error.response.status === 401) {
      router.push('/signin') // 替換為 Vue Router 中設定的登入路徑
    }
    return Promise.reject(error)
  })

const apiRequest = async (method, endpoint, data = null, callback = null) => {
  const url = `${apiDomain}/${endpoint}`
  try {
    const config = {
      method,
      url
    }
    // 如果是 GET 請求，使用 params 而不是 data
    if (method.toLowerCase() === 'get' && data) {
      config.params = data
    } else if (data) {
      config.data = data
    }
    const response = await apiClient(config)
    console.log(response)
    if (response.status !== 200) {
      throw new Error('Network response was not 200')
    }
    if (callback) callback(null, response.data)
  } catch (error) {
    if (callback) callback(error)
    console.error(`Error in ${method.toUpperCase()} request to ${url}:`, error)
    // throw error
  }
}

export const Verify2FACode = (data, callback) => apiRequest('POST', 'api/Account/Verify2FACode', data, callback)
export const GetTradeList = (callback) => apiRequest('GET', 'api/Asset/GetAllTrades', null, callback)
export const GetRealizedAsset = (callback) => apiRequest('GET', 'api/Asset/GetRealizedAsset', null, callback)
export const GetMarginAndWalletRecord = (callback) => apiRequest('GET', 'api/Asset/GetMarginAndWalletRecord', null, callback)
export const GetPositionList = (callback) => apiRequest('GET', 'api/Order/GetAllPositions', null, callback)
export const ClosePosition = (data, callback) => apiRequest('GET', 'api/Order/ClosePosition', data, callback)
export const GetAIMessage = (data, callback) => apiRequest('POST', 'api/Learn/GetMessage', data, callback)
export default {
  apiDomain,
  getData () {
    return apiClient.get('/your-endpoint') // 替換成你的 API 路徑
  },
  postData (data) {
    return apiClient.post('/your-endpoint', data) // 替換成你的 API 路徑
  },
  updateData (id, data) {
    return apiClient.put(`/your-endpoint/${id}`, data) // 替換成你的 API 路徑
  },
  deleteData (id) {
    return apiClient.delete(`/your-endpoint/${id}`) // 替換成你的 API 路徑
  }
}
