<template>
    <div id="app">
      <!-- Navbar -->
      <header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </header>

      <!-- Sidebar and Main Content -->
      <div class="container-fluid">
        <div class="row vh-100">
          <!-- Sidebar -->
          <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
            <div class="position-sticky pt-3">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <router-link to="/dashboard/asset" class="nav-link">Asset</router-link>
                  <!-- <a class="nav-link active" href="#">
                    <span data-feather="home"></span>
                    Dashboard
                  </a> -->
                </li>
                <li class="nav-item">
                  <router-link to="/dashboard/order" class="nav-link">Order</router-link>
                  <!-- <a class="nav-link" href="#">
                    <span data-feather="file"></span>
                    Orders
                  </a> -->
                </li>
                <li class="nav-item">
                  <router-link to="/dashboard/learn" class="nav-link">Chat</router-link>
                  <!-- <a class="nav-link" href="#">
                    <span data-feather="file"></span>
                    Orders
                  </a> -->
                </li>
              </ul>

              <!-- Saved reports section -->
              <!-- <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>Saved reports</span>
                <a class="link-secondary" href="#" aria-label="Add a new report">
                  <span data-feather="plus-circle"></span>
                </a>
              </h6>
              <ul class="nav flex-column mb-2">
                <li class="nav-item" v-for="report in savedReports" :key="report.id">
                  <a class="nav-link" href="#">
                    <span data-feather="file-text"></span>
                    {{ report.name }}
                  </a>
                </li>
              </ul> -->
            </div>
          </nav>

          <!-- Main content area -->
          <main id="main-content" class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <router-view />
          </main>
        </div>
      </div>
    </div>
  </template>

<script>
// import axios from 'axios'
// 引入你自定義的組件
// import AssetComponent from './AssetComponent.vue'
// import OrderComponent from './OrderComponent.vue'

export default {
  name: 'DashboardView',
  data () {
    return {
      savedReports: [
        { id: 1, name: 'Current month' },
        { id: 2, name: 'Last quarter' },
        { id: 3, name: 'Social engagement' },
        { id: 4, name: 'Year-end sale' }
      ],
      currentComponent: null // 用於動態顯示內容
    }
  },
  methods: {
    loadContent (type) {
      switch (type) {
        case 'asset':
          this.currentComponent = 'AssetComponent'
          break
        case 'order':
          this.currentComponent = 'OrderComponent'
          break
        default:
          this.currentComponent = null
      }
    }
  },
  mounted () {
    // Feather icons 初始化
    if (window.feather) {
      window.feather.replace()
    }
    if (!sessionStorage.getItem('token')) {
      console.log('NOOOOO')
      this.$router.push('/signin')
    }
  },
  components: {
    // 引入組件
    // AssetComponent,
    // OrderComponent
  }
}
</script>

  <style scoped>
  .bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  #main-content {
    scrollbar-gutter: stable;
  }
  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }
  </style>
