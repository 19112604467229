<template>
  <div>
    <!-- 資產損益表 -->
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">資產損益表</h1>
    </div>
    <canvas ref="AssetChart" class="my-4 w-100" id="AssetChart" width="900" height="380"></canvas>

    <!-- 浮盈虧表 -->
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">浮盈虧表</h1>
      <div>
        <div>目前浮盈：{{this.lastAllPositionVal}}</div>
        <div>
          平倉比率：<input type="text" v-model="closePositionRatio">
          <button @click="closeAllPositionByRatio" type="button">平倉</button>
        </div>
      </div>
    </div>
    <canvas ref="walletDiffChart" class="my-4 w-100" id="walletDiffChart" width="900" height="380"></canvas>

    <!-- 成交紀錄表格 -->
    <h2>成交紀錄</h2>
    <div class="table-responsive">
      <table class="table table-striped table-sm">
        <thead>
          <tr>
            <th scope="col">時間</th>
            <th scope="col">項目</th>
            <th scope="col">方向</th>
            <th scope="col">價格</th>
            <th scope="col">數量</th>
            <th scope="col">金額</th>
            <th scope="col">實際盈虧</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tradeList" :key="index">
            <td>{{ formatDate(item.TradeDT) }}</td>
            <td>{{ item.Symbol }}</td>
            <td>{{ item.Side }}</td>
            <td>{{ item.Price }}</td>
            <td>{{ item.Quantity }}</td>
            <td>{{ item.Amount }}</td>
            <td>{{ item.RealizedPnl }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import { ClosePosition, GetTradeList, GetRealizedAsset, GetMarginAndWalletRecord } from '@/apiService'

Chart.register(...registerables)

export default {
  name: 'AssetView',
  data () {
    return {
      lastAllPositionVal: 0,
      closePositionRatio: 0.95,
      tradeList: [],
      assetData: {
        rndIdx: [],
        rndAry: []
      },
      marginData: {
        chartIdx: [],
        newMarginRec: [],
        newWalletRec: []
      },
      AssetChart: null, // 用於存儲圖表實例
      walletDiffChart: null, // 用於存儲圖表實例
      isAssetLoading: true,
      isWalletLoading: true,
      isError401: false
    }
  },
  methods: {
    formatDate (date) {
      return new Date(date).toLocaleString('zh-TW', { timeZone: 'Asia/Taipei', hour12: false })
    },
    closeAllPositionByRatio () {
      ClosePosition({ closeRatio: this.closePositionRatio }, (error, data) => {
        console.log(error)
        if (!error && data) {
          alert('部分平倉已設定完成, 請等待平倉!')
        }
        if (error && error.status === 401) {
          this.isError401 = true
        }
      })
    },
    loadDataAndContent () {
      this.isAssetLoading = true
      this.isWalletLoading = true
      this.getTradeList()
      this.getRealizedAsset()
      this.getMarginAndWalletRecord()
    },
    getTradeList () {
      GetTradeList((error, data) => {
        console.log(error)
        if (!error && data) {
          this.tradeList = data
        }
        if (error && error.status === 401) {
          this.isError401 = true
        }
      })
    },
    getRealizedAsset () {
      GetRealizedAsset((error, data) => {
        if (!error && data) {
          this.assetData.rndIdx = data.rndIdx
          this.assetData.rndAry = data.rndAry
          this.$nextTick(() => { // 確保 DOM 更新完成
            this.initAssetChart()
            this.isAssetLoading = false
          })
        }
        if (error && error.status === 401) {
          this.isError401 = true
        }
      })
    },
    getMarginAndWalletRecord () {
      GetMarginAndWalletRecord((error, data) => {
        if (!error && data) {
          this.marginData.chartIdx = data.chartIdx
          this.marginData.newMarginRec = data.newMarginRec
          this.marginData.newWalletRec = data.newWalletRec
          this.lastAllPositionVal = data.newMarginRec[data.newMarginRec.length - 1] - data.newWalletRec[data.newWalletRec.length - 1]
          this.$nextTick(() => { // 確保 DOM 更新完成
            this.initwalletDiffChart()
            this.isWalletLoading = false
          })
        }
        if (error && error.status === 401) {
          this.isError401 = true
        }
      })
    },
    initAssetChart () {
      const ctx = this.$refs.AssetChart?.getContext('2d')
      if (!ctx) {
        console.log('NOOOOO')
        return
      }
      if (this.AssetChart) {
        this.AssetChart.destroy() // 銷毀之前的圖表實例，避免重複繪製
      }
      this.AssetChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.assetData.rndIdx,
          datasets: [{
            data: this.assetData.rndAry,
            lineTension: 0,
            backgroundColor: 'transparent',
            borderColor: '#007bff',
            borderWidth: 1,
            pointBackgroundColor: '#007bff',
            pointRadius: 0
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: false
            }
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }
      })
    },
    initwalletDiffChart () {
      const ctx = this.$refs.walletDiffChart?.getContext('2d')
      if (!ctx) {
        console.log('NOOOOO')
        return
      }
      if (this.walletDiffChart) {
        this.walletDiffChart.destroy() // 銷毀之前的圖表實例，避免重複繪製
      }
      this.walletDiffChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.marginData.chartIdx,
          datasets: [{
            data: this.marginData.newMarginRec,
            lineTension: 0,
            backgroundColor: 'transparent',
            borderColor: '#007bff',
            borderWidth: 1,
            pointBackgroundColor: '#007bff',
            pointRadius: 0
          }, {
            data: this.marginData.newWalletRec,
            lineTension: 0,
            backgroundColor: 'transparent',
            borderColor: '#00FF2B',
            borderWidth: 1,
            pointBackgroundColor: '#007bff',
            pointRadius: 0
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: false
            }
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }
      })
    }
  },
  mounted () {
    // this.getTradeList()
    // this.getRealizedAsset()
    // this.getMarginAndWalletRecord()
    // 初始化圖表代碼可以在這裡新增
    // 例如：this.initMyChart() 和 this.initWalletDiffChart()
    this.loadDataAndContent()
  },
  beforeUnmount () {
    if (this.AssetChart) {
      this.AssetChart.destroy() // 銷毀 Chart.js 實例
      this.AssetChart = null // 釋放引用
    }
    if (this.walletDiffChart) {
      this.walletDiffChart.destroy() // 銷毀 Chart.js 實例
      this.walletDiffChart = null // 釋放引用
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.isError401 && (this.isAssetLoading || this.isWalletLoading)) {
      next(false) // 阻止導航
    } else {
      next() // 允許導航
    }
  }
}
</script>

<style scoped></style>
