<template>
    <main class="form-signin">
        <div id="app">

            <div class="form-floating">
                <input v-model="userNums" type="text" class="form-control" placeholder="10-Nums Code" @focus="checkClipboard">
                <label>10-Nums Code</label>
            </div>

            <div class="checkbox mb-3">
                <!-- <label>
        <input type="checkbox" value="remember-me"> Remember me
    </label> -->
            </div>
            <button @click="signinClick" id="btnLogin" class="w-100 btn btn-lg btn-primary">登入</button>
        </div>
    </main>
</template>

<script>
import { Verify2FACode } from '@/apiService'
import { useStore } from 'vuex'
// import apiDomain from './apiService.js'
// console.log(apiService.apiDomain)
export default {
  setup () {
    const store = useStore()
    return { store }
  },
  data () {
    return {
      userNums: '',
      new2faCodeImg: '',
      count: 0
    }
  },
  methods: {
    async checkClipboard () {
      const text = await navigator.clipboard.readText()
      if (/^\d{6}$/.test(text)) {
        this.userNums = text
        // this.signinClick()
      }
    },
    login (token) {
      // 模擬成功登入，將 token 保存到 sessionStorage
      sessionStorage.setItem('token', token)
      this.$store.dispatch('login') // 更新 Vuex 狀態
    },
    signinClick () {
      console.log(this.userNums)
      Verify2FACode({ UserKey: this.userNums }, (error, data) => {
        if (!error && data && data.pwdCheck === true) {
          if (data.token) {
            // sessionStorage.setItem('token', data.token)
            this.login(data.token)
            // console.log('token', sessionStorage.getItem('token'))
            this.$router.push('/dashboard') // Vue Router 導向到 dashboard 頁面
          }
        } else {
          alert('登入失敗')
        }
      })
    }
  }
}
</script>

<style scoped>
html,
body {
    height: 100%;
}

body {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
}

.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}

.form-signin .checkbox {
    font-weight: 400;
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
</style>
