<template>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
          <div class="mb-3">
            <label for="amount" class="form-label">金額:</label>
            <input type="number" class="form-control" v-model="amount" placeholder="輸入金額" />
          </div>
          <div class="mb-3">
            <label for="fromCurrency" class="form-label">從:</label>
            <select class="form-select" v-model="fromCurrency">
              <option v-for="(name, code) in currencies" :key="code" :value="code">
                {{ code }} - {{ name }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <label for="toCurrency" class="form-label">到:</label>
            <select class="form-select" v-model="toCurrency">
              <option v-for="(name, code) in currencies" :key="code" :value="code">
                {{ code }} - {{ name }}
              </option>
            </select>
          </div>
          <button class="btn btn-primary w-100" @click="convertCurrency">換算</button>
          <p class="mt-3 text-center" v-if="result">{{ result }}</p>
        </div>
      </div>
    </div>
  </template>

<script>
export default {
  data () {
    return {
      amount: '',
      fromCurrency: '',
      toCurrency: '',
      currencies: {},
      result: ''
    }
  },
  mounted () {
    this.fetchCurrencies()
  },
  methods: {
    async fetchCurrencies () {
      try {
        const response = await fetch('https://openexchangerates.org/api/currencies.json')
        this.currencies = await response.json()
      } catch (error) {
        console.error('Error fetching currencies:', error)
      }
    },
    async convertCurrency () {
      const { amount, fromCurrency, toCurrency } = this

      if (!amount || !fromCurrency || !toCurrency) {
        this.result = '請填寫所有欄位'
        return
      }

      try {
        const response = await fetch(`https://api.exchangerate-api.com/v4/latest/${fromCurrency}`)
        const data = await response.json()
        const rate = data.rates[toCurrency]

        if (rate) {
          const convertedAmount = (amount * rate).toFixed(2)
          this.result = `${amount} ${fromCurrency} = ${convertedAmount} ${toCurrency}`
        } else {
          this.result = '無法找到對應的匯率'
        }
      } catch (error) {
        this.result = '請求失敗，請稍後再試'
      }
    }
  }
}
</script>

  <style scoped>
  /* 在此處添加任何特定於組件的樣式 */
  </style>
