import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoggedIn: !!sessionStorage.getItem('token')
  },
  getters: {
  },
  mutations: {
    setLoginState (state, status) {
      state.isLoggedIn = status
    }
  },
  actions: {
    login ({ commit }) {
      commit('setLoginState', true)
    },
    logout ({ commit }) {
      commit('setLoginState', false)
    }
  },
  modules: {
  }
})
