<template>
    <div>

      <div class="container my-4">
        <header class="text-center mb-4">
          <h1>歡迎來到 CoinGo</h1>
          <p>提供各種金融、貨幣和交易的實用工具，幫助您更好地管理財務。</p>
        </header>

        <!-- 金融工具 -->
        <h2 class="mb-4">金融工具</h2>
        <div class="row">
          <div class="col-md-4">
            <div class="card mb-4">
              <div class="card-body">
                <h5 class="card-title">匯率轉換</h5>
                <p class="card-text">快速轉換不同貨幣之間的匯率。</p>
                <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                  data-bs-target="#exchangeRateModal">
                  使用匯率轉換
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mb-4">
              <div class="card-body">
                <h5 class="card-title">投資計算器</h5>
                <p class="card-text">計算您的投資回報和風險。</p>
                <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                  data-bs-target="#investmentCalculatorModal">
                  使用投資計算器
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- 其他工具 -->
        <h2 class="mb-4">其他工具</h2>
        <div class="row">
          <div class="col-md-4">
            <div class="card mb-4">
              <div class="card-body">
                <h5 class="card-title">交易記錄</h5>
                <p class="card-text">記錄和分析您的交易活動。</p>
                <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                  data-bs-target="#transactionHistoryModal">
                  查看交易記錄
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mb-4">
              <div class="card-body">
                <h5 class="card-title">檔案加解密</h5>
                <p class="card-text">在檔案與加密Base64來回橫跳。</p>
                <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                  data-bs-target="#fileEnDeModal">
                  開始加解密
                </button>
              </div>
            </div>
          </div>
        </div>

        <footer class="text-center mt-4">
          <p>© 2024 CoinGo | <a href="#">隱私政策</a> | <a href="#">聯繫我們</a></p>
        </footer>
      </div>

        <!-- 匯率轉換 Modal -->
        <div class="modal fade" id="exchangeRateModal" tabindex="-1" aria-labelledby="exchangeRateModalLabel" aria-hidden="true">
          <div class="modal-dialog">
              <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="exchangeRateModalLabel">匯率轉換</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                  <ExchangeRateView/>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
              </div>
              </div>
          </div>
        </div>
        <!-- 檔案加解密 Modal -->
        <div class="modal fade" id="fileEnDeModal" tabindex="-1" aria-labelledby="fileEnDeModalLabel" aria-hidden="true">
          <div class="modal-dialog">
              <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="fileEnDeModalLabel">匯率轉換</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                  <FileEnDeView/>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
              </div>
              </div>
          </div>
        </div>
    </div>
  </template>
<script>
import ExchangeRateView from './ExchangeRateView.vue'
import FileEnDeView from './FileEnDeView.vue'

export default {
  components: {
    ExchangeRateView, // 註冊您的組件
    FileEnDeView
  }
}

</script>

  <style>
  /* 可以在這裡添加您的自定義樣式 */
  </style>
